import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import { CHARGES_RECEIVED, SET_CHARGE } from "../types/charges";
import { MERCHANTS_RECEIVED } from "../types/merchants";

const ChargesReducer = (state, { type, payload }) => {
  switch (type) {
    case CHARGES_RECEIVED:
      return { ...state, charges: payload };
    case MERCHANTS_RECEIVED:
      return { ...state, merchants: payload };
    case SET_CHARGE:
      return { ...state, charge: payload };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
export default ChargesReducer;
