import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { UserContext } from "../../context/UserContext";
import { formatMonto, getTotalCompras } from "../../utils";
import { ModalContext } from "../../context/ModalContext";

const CustomerActions = ({ customer }) => {
  const [copied, setCopied] = useState(false);
  const { recoverPassword } = useContext(UserContext);

  const { link, getPasswordResetLink } = useContext(CustomerContext);

  const { success } = useContext(ModalContext);

  useEffect(() => {
    if (link !== null) {
      let input = document.createElement("input");
      input.value = link;
      input.id = "copy-input";
      document.body.appendChild(input);
      var copyText = document.getElementById("copy-input");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      navigator.clipboard.writeText(copyText.value).then(() => {
        setCopied(true);
      });
      input.remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  useEffect(() => {
    if (copied) {
      success("Enlace copiado al portapapeles.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copied]);

  return (
    <div className="container-fluid px-0">
      <h4>Reestablecer Contraseña</h4>

      <button
        className="btn btn-outline-dark me-2 my-1"
        onClick={() => recoverPassword(customer.email)}
      >
        <i className="fa fa-envelope me-2"></i> Enviar Correo
      </button>
      <button
        className="btn btn-outline-dark me-2 my-1"
        onClick={() => getPasswordResetLink(customer.email)}
      >
        <i className="fa fa-link me-2"></i> Generar Link
      </button>
      <h4 className="mt-4">
        Compras Totales: {"$"}
        {formatMonto(getTotalCompras(customer))} MXN
      </h4>
    </div>
  );
};

export default CustomerActions;
