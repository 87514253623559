import React, { createContext, useContext, useReducer } from "react";
import ProductsService from "../services/ProductsService";
import ProductsReducer from "../reducers/ProductsReducer";
import {
  CREATE_PRODUCT,
  PRODUCTS_RECEIVED,
  SET_PRODUCT,
  SET_PROPERTY_PRODUCT,
} from "../types/products";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  products: null,
  product: null,
};

export const ProductsContext = createContext(initialState);

export const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProductsReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getAllProducts = () => {
    ProductsService.getAllProducts().then((res) => {
      const { products } = res.data;
      dispatch({ type: PRODUCTS_RECEIVED, payload: products });
    });
  };

  const getSingleProduct = (product_id) => {
    ProductsService.getSingleProduct(product_id).then((res) => {
      const { product } = res.data;
      dispatch({ type: SET_PRODUCT, payload: product });
    });
  };

  const setProduct = (product) => {
    dispatch({ type: SET_PRODUCT, payload: product });
  };

  const createProduct = (product) => {
    dispatch({ type: CREATE_PRODUCT, payload: product });
  };

  const saveProduct = (product) => {
    dispatch({ type: SHOW_SPINNER });
    let service = ProductsService.putProduct;
    if (isNaN(parseInt(product.product_id))) {
      service = ProductsService.postProduct;
    }
    service(product)
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        success("Product saved.");
        clearModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const setPropertyProduct = (key, value) => {
    dispatch({ type: SET_PROPERTY_PRODUCT, payload: { key, value } });
  };

  const deleteProduct = (product_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    ProductsService.deleteProduct(product_id)
      .then(() => {
        if (typeof callback === "function") {
          callback();
        }
        dispatch({ type: HIDE_SPINNER });
        success("Product deleted.");
        clearModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        if (typeof callback === "function") {
          callback();
        }
        alert(error);
      });
  };

  return (
    <ProductsContext.Provider
      value={{
        ...state,
        setProduct,
        saveProduct,
        deleteProduct,
        createProduct,
        getAllProducts,
        getSingleProduct,
        setPropertyProduct,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
