import React, { useContext } from "react";
import { S3_ENDPOINT, getAddress } from "../../utils";
import GoogleLocationMap from "../maps/GoogleLocationMap";
import MerchantForm from "./MerchantForm";
import { MerchantsContext } from "../../context/MerchantsContext";
import { ModalContext } from "../../context/ModalContext";
import { LocationsContext } from "../../context/LocationsContext";

const MerchantData = ({ merchant }) => {
  const { modalComponent } = useContext(ModalContext);
  const { setLocation, createLocation } = useContext(LocationsContext);
  const { setMerchant, saveMerchant, setPropertyMerchant } =
    useContext(MerchantsContext);
  const { file, name, address, opening_time, closing_time } = merchant;

  const getSrc = () => {
    let src = "logo.png";
    if (file && file !== null) {
      src = `${file.name}.${file.type}`;
    }
    return `${S3_ENDPOINT}/${src}`;
  };

  const editMerchant = () => {
    setMerchant(merchant);
    if (merchant.address !== null) {
      setLocation(merchant.address);
    } else {
      createLocation();
    }
    modalComponent(
      "Editar Comercio",
      <MerchantForm
        modifier={setPropertyMerchant}
        postMerchant={saveMerchant}
      />
    );
  };

  return (
    <div className="container-fluid pt-3 pb-1">
      <div className="row py-3">
        <div className="col-12 col-md-6">
          <img
            src={getSrc()}
            className="w-100 mb-3 border p-3 br-10"
            alt="Perfil de Comercio"
          />
          <div className="p-2">
            <h4 className="mt-4 mb-0">{name}</h4>
            <div className="mt-3">
              <i className="fa fa-clock me-2"></i>
              {opening_time} - {closing_time}
            </div>
          </div>
          <p className="mb-0">{getAddress(address)}</p>
          <button
            onClick={editMerchant}
            className="btn btn-outline-primary mt-3"
          >
            <i className="fa fa-edit me-3"></i>Editar Información
          </button>
        </div>
        <div className="col-12 col-md-6">
          {address !== null && <GoogleLocationMap location={address} />}
        </div>
      </div>
    </div>
  );
};

export default MerchantData;
