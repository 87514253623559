import React, { createContext, useReducer } from "react";
import FiltersReducer from "../reducers/FiltersReducer";
import { SET_FILTER, SET_FILTER_VALUES } from "../types";

const initialState = {
  query: "",
};

export const FiltersContext = createContext(initialState);

export const FiltersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FiltersReducer, initialState);

  const setFilter = (key, value) => {
    if (value === "Todo") {
      value = null;
    }
    dispatch({ type: SET_FILTER, payload: { key, value } });
  };

  const setFilterValues = (key, values) => {
    dispatch({ type: SET_FILTER_VALUES, payload: { key, values } });
  };

  return (
    <FiltersContext.Provider value={{ ...state, setFilter, setFilterValues }}>
      {children}
    </FiltersContext.Provider>
  );
};
