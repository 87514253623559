import React from "react";
import { Router } from "@reach/router";
import AdminCharges from "./admin/AdminCharges";
import AdminClientes from "./admin/AdminClientes";
import AdminUsuarios from "./admin/AdminUsuarios";
import AdminInvoices from "./admin/AdminInvoices";
import AdminCustomerForm from "./admin/AdminCustomerForm";
import AdminSingleUsuario from "./admin/AdminSingleUsuario";
import AdminMerchants from "./admin/AdminMerchants";
import AdminSingleMerchant from "./admin/AdminSingleMerchant";
import AdminVehicles from "./admin/AdminVehicles";
import AdminAccesses from "./admin/AdminAccesses";

const Admin = () => {
  return (
    <Router>
      <AdminUsuarios path="/users" />
      <AdminClientes path="/customers" />
      <AdminInvoices path="/subscriptions" />
      <AdminCharges path="/charges" default />
      <AdminMerchants path="/merchants" />
      <AdminVehicles path="/vehicles" />
      <AdminAccesses path="/accesses" />
      <AdminSingleMerchant path="/merchants/:merchant_id" />
      <AdminCustomerForm path="/customer/:customer_id/edit" />
      <AdminSingleUsuario path="/customer/:customer_id" />
    </Router>
  );
};

export default Admin;
