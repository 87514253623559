import React, { useContext, useEffect } from "react";
import { MerchantsContext } from "../../context/MerchantsContext";
import ProductsTable from "../../components/products/ProductsTable";
import PanelTitle from "../../components/global/PanelTitle";
import { ModalContext } from "../../context/ModalContext";
import ProductForm from "../../components/products/ProductForm";
import { ProductsContext } from "../../context/ProductsContext";
import MerchantData from "../../components/merchants/MerchantData";

const AdminSingleMerchant = ({ merchant_id }) => {
  const { merchant, getSingleMerchant } = useContext(MerchantsContext);
  const { createProduct } = useContext(ProductsContext);
  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getSingleMerchant(merchant_id);
  }, [merchant_id]);

  const handleDeleteCallback = () => {
    getSingleMerchant(merchant_id);
  };

  const handleCreate = () => {
    createProduct();
    modalComponent(
      "Agregar Producto",
      <ProductForm merchant_id={merchant_id} />
    );
  };

  const renderProducts = () => {
    if (merchant && merchant !== null) {
      const { products } = merchant;
      if (Array.isArray(products)) {
        return (
          <div className="card p-3 shadow">
            <PanelTitle title="Products" onClick={handleCreate} />
            <ProductsTable
              products={products}
              handleDeleteCallback={handleDeleteCallback}
            />
          </div>
        );
      }
    }
  };

  return (
    <div className="container-fluid">
      <h1>Comercio</h1>
      <div className="card px-0 py-0 shadow mb-3">
        {merchant !== null && <MerchantData merchant={merchant} />}
      </div>
      {renderProducts()}
    </div>
  );
};

export default AdminSingleMerchant;
