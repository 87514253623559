import api from "./api";

const route = "/products";

const ProductsService = {
  getAllProducts: () => api.get(`${route}/admin/all`),
  getMerchantProducts: (merchant_id) =>
    api.get(`${route}/merchant/${merchant_id}`),
  getSingleProduct: (product_id) => api.get(`${route}/${product_id}`),
  postProduct: (product) => api.post(route, { ...product }),
  putProduct: (product) => api.put(route, { ...product }),
  deleteProduct: (product_id) => api.delete(`${route}/${product_id}`),
};
export default ProductsService;
