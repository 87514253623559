import React, { useContext } from "react";
import { getValue } from "../../utils";
import { LocationsContext } from "../../context/LocationsContext";
import GoogleMapsAutocomplete from "../maps/GoogleMapsAutocomplete";

const LocationForm = ({
  title,
  saveAction,
  hideButtons,
  handleCancel,
  handleCallback,
}) => {
  const { location, saveLocation, setPropertyLocation } =
    useContext(LocationsContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (typeof saveAction === "function") {
      return saveAction(location);
    }
    saveLocation(location, handleCallback);
  };

  const handleSelectAutocomplete = (value) => {
    const splitLocation = String(value).split(",");
    setPropertyLocation("street", value);
    let fields = ["country", "state", "city", "neighborhood", "street"];
    for (let i = splitLocation.length - 1; i >= 0; i--) {
      const current = String(splitLocation[i]).trim();
      const key = fields.shift();
      if (key) setPropertyLocation(key, current);
    }
  };

  const renderButtons = () => {
    if (!hideButtons) {
      return (
        <div className="row mt-3">
          <div className="col">
            <button type="submit" className="btn btn-primary w-100">
              Guardar
            </button>
          </div>
          {handleCancel && (
            <div className="col text-end">
              <button
                type="button"
                onClick={handleCancel}
                className="btn btn-link text-muted px-0"
              >
                Cancelar
              </button>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <form className="row" onSubmit={handleSubmit}>
      {title && <h2>{title}</h2>}
      <div className="col-md-6 mb-2">
        <label htmlFor="street" className="form-label">
          Calle
        </label>
        <GoogleMapsAutocomplete
          value={getValue(location, "street")}
          modifier={(value) => setPropertyLocation("street", value)}
          handleSelect={handleSelectAutocomplete}
        />
      </div>
      <div className="col-md-3 col-6 mb-2">
        <label htmlFor="number" className="form-label">
          Número
        </label>
        <input
          type="text"
          id="number"
          name="number"
          className="form-control"
          value={getValue(location, "number")}
          onChange={(event) =>
            setPropertyLocation("number", event.target.value)
          }
          required
        />
      </div>

      <div className="col-md-3 col-6 mb-2">
        <label htmlFor="interiorNumber" className="form-label">
          Interior
        </label>
        <input
          type="text"
          id="interiorNumber"
          name="interiorNumber"
          className="form-control"
          value={getValue(location, "interior_number")}
          onChange={(event) =>
            setPropertyLocation("interior_number", event.target.value)
          }
        />
      </div>

      <div className="col-12 mb-2">
        <label htmlFor="neighborhood" className="form-label">
          Colonia
        </label>
        <input
          type="text"
          id="neighborhood"
          name="neighborhood"
          className="form-control"
          value={getValue(location, "neighborhood")}
          onChange={(event) =>
            setPropertyLocation("neighborhood", event.target.value)
          }
          required
        />
      </div>
      <div className="col-12 mb-2">
        <label htmlFor="city" className="form-label">
          Ciudad
        </label>
        <input
          type="text"
          id="city"
          name="city"
          className="form-control"
          value={getValue(location, "city")}
          onChange={(event) => setPropertyLocation("city", event.target.value)}
          required
        />
      </div>
      <div className="col-md-4 col-6 mb-2">
        <label htmlFor="zip_code" className="form-label">
          Código Postal
        </label>
        <input
          type="text"
          id="zip_code"
          name="zip_code"
          className="form-control"
          value={getValue(location, "zip_code")}
          onChange={(event) =>
            setPropertyLocation("zip_code", event.target.value)
          }
          required
        />
      </div>
      <div className="col-md-4 col-6 mb-2">
        <label htmlFor="state" className="form-label">
          Estado
        </label>
        <input
          type="text"
          id="state"
          name="state"
          className="form-control"
          value={getValue(location, "state")}
          onChange={(event) => setPropertyLocation("state", event.target.value)}
          required
        />
      </div>
      <div className="col-md-4 col-12 mb-2">
        <label htmlFor="country" className="form-label">
          País
        </label>
        <input
          type="text"
          id="country"
          name="country"
          className="form-control"
          value={getValue(location, "country")}
          onChange={(event) =>
            setPropertyLocation("country", event.target.value)
          }
          required
        />
      </div>
      <div className="col-12">{renderButtons()}</div>
    </form>
  );
};

export default LocationForm;
