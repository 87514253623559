import React, { useContext, useEffect, useState } from "react";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import ChargesTable from "../../components/charges/ChargesTable";
import { ChargesContext } from "../../context/ChargesContext";
import Pagination from "../../components/global/Pagination";
import { UserContext } from "../../context/UserContext";
import { MerchantsContext } from "../../context/MerchantsContext";

const AdminCharges = () => {
  const [page, setPage] = useState(1);
  const [type, setType] = useState(null);
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [merchantId, setMerchantId] = useState("");

  const { merchants, getAllMerchants } = useContext(MerchantsContext);
  const { spinner, charges, getAllCharges } = useContext(ChargesContext);

  const { user } = useContext(UserContext);

  useEffect(() => {
    getAllMerchants();
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && !spinner) {
      fetchCharges();
    }
  }, [merchantId, startDate, endDate, page, status, query]);

  const fetchCharges = () => {
    getAllCharges({
      page,
      query,
      status,
      end_date: endDate,
      start_date: startDate,
      merchant_id: user.merchant_id ? user.merchant_id : merchantId,
    });
  };

  const setDates = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const filterCharges = () => {
    let result = charges;
    if (Array.isArray(charges)) {
      switch (type) {
        case "purchase":
          result = charges.filter((purchase) => !purchase.invoice_id);
          break;
        case "invoice":
          result = charges.filter((purchase) => purchase.invoice_id);
          break;
      }
    }
    return result;
  };

  const renderMerchants = () => {
    if (Array.isArray(merchants)) {
      return merchants.map((merchant) => (
        <option key={merchant.merchant_id} value={merchant.merchant_id}>
          {merchant.name}
        </option>
      ));
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Pagos" callback={setDates} />
      <div className="container-fluid px-0">
        <input
          type="text"
          value={query}
          className="form-control mb-3"
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Buscar por nombre o correo electrónico..."
        />
      </div>
      <div className="card  p-3 mb-3 shadow-sm">
        <div className="row align-items-center mb-3">
          <div className="col-12 col-md-6">
            <button
              className={`btn btn-sm btn-${
                status === "" ? "primary" : "light"
              } border br-0`}
              onClick={() => setStatus("")}
            >
              Todo
            </button>
            <button
              className={`btn btn-sm btn-${
                Array.isArray(status) && status.includes("active")
                  ? "primary"
                  : "light"
              } border br-0`}
              onClick={() =>
                setStatus(["active", "completed", "success", "captured"])
              }
            >
              Exitosos
            </button>
            <button
              className={`btn btn-sm btn-${
                Array.isArray(status) && status.includes("cancelled")
                  ? "primary"
                  : "light"
              } border br-0`}
              onClick={() => setStatus(["cancelled", "failed", "pending"])}
            >
              Cancelados
            </button>
            <button
              className={`btn btn-sm btn-${
                Array.isArray(status) && status.includes("processing")
                  ? "primary"
                  : "light"
              } border br-0`}
              onClick={() => setStatus(["processing", "on-hold", "authorized"])}
            >
              Pendientes
            </button>
          </div>
          <div className="col-12 col-md-3">
            <button
              className={`btn btn-sm btn-${
                type === null ? "primary" : "light"
              } border br-0`}
              onClick={() => setType(null)}
            >
              Todos
            </button>
            <button
              className={`btn btn-sm btn-${
                type === "purchase" ? "primary" : "light"
              } border br-0`}
              onClick={() => setType("purchase")}
            >
              Compras
            </button>
            <button
              className={`btn btn-sm btn-${
                type === "invoice" ? "primary" : "light"
              } border br-0`}
              onClick={() => setType("invoice")}
            >
              Cargos
            </button>
          </div>
          {user.role !== "merchant" && (
            <div className="col-12 col-md-3">
              <select
                value={merchantId}
                className="form-control"
                onChange={(e) => setMerchantId(e.target.value)}
              >
                {renderMerchants()}
              </select>
            </div>
          )}
        </div>
        <ChargesTable charges={filterCharges()} user={user} showType />
        <Pagination modifier={setPage} />
      </div>
    </div>
  );
};

export default AdminCharges;
