import { ACCESSES_RECEIVED, SET_ACCESS } from "../types/access";

const AccessReducer = (state, { type, payload }) => {
  switch (type) {
    case ACCESSES_RECEIVED:
      return { ...state, accesses: payload };
    case SET_ACCESS:
      return { ...state, access: payload };
    default:
      return { ...state };
  }
};
export default AccessReducer;
