import React from "react";
import ProductRow from "./ProductRow";

const ProductsTable = ({ products, handleDeleteCallback }) => {
  const renderProducts = () => {
    if (Array.isArray(products)) {
      if (products.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay compras registradas.</td>
          </tr>
        );
      }
      return products.map((product) => (
        <ProductRow
          product={product}
          key={product.product_id}
          handleDeleteCallback={handleDeleteCallback}
        />
      ));
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>#ID</td>
            <td>Nombre</td>
            <td>Orden</td>
            <td>Monto</td>
            <td>Periodo</td>
            <td>Límite</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>{renderProducts()}</tbody>
      </table>
    </div>
  );
};

export default ProductsTable;
