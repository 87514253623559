import {
  INSCRITOS_RECIBIDOS,
  INGRESOS_RECBIDOS,
  MENSUALES_RECIBIDOS,
} from "../types";
import { MERCHANTS_RECEIVED } from "../types/merchants";

const AnaliticasReducer = (state, { type, payload }) => {
  switch (type) {
    case INSCRITOS_RECIBIDOS:
      return { ...state, ...payload };
    case MERCHANTS_RECEIVED:
      return { ...state, merchants: payload };
    case INGRESOS_RECBIDOS:
      return { ...state, ...payload };
    case MENSUALES_RECIBIDOS:
      return { ...state, ...payload };
    default:
      return { ...state };
  }
};

export default AnaliticasReducer;
