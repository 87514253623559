import React, { useContext, useEffect } from "react";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import HeaderRow from "../../components/global/HeaderRow";
import { AccessContext } from "../../context/AccessContext";
import { FiltersContext } from "../../context/FiltersContext";
import AccessRow from "../../components/access/AccessRow";
import { MerchantsContext } from "../../context/MerchantsContext";

const AdminAccesses = () => {
  const { merchants, getAllMerchants } = useContext(MerchantsContext);
  const { merchant_id, setFilter } = useContext(FiltersContext);
  const { accesses, getAccesses } = useContext(AccessContext);

  useEffect(() => {
    getAllMerchants();
  }, []);

  const handleChange = (start_date, end_date) => {
    getAccesses({ start_date, end_date, merchant_id });
  };

  const renderAccesses = () => {
    if (Array.isArray(accesses)) {
      let accessesRender = [...accesses];
      if (merchant_id && merchant_id !== "" && merchant_id !== null) {
        accessesRender = accessesRender.filter(
          (access) => parseInt(access.merchant_id) === parseInt(merchant_id)
        );
      }
      if (accessesRender.length === 0) {
        return <p className="mb-0 p-2">No accesses for this period.</p>;
      }
      return accessesRender.map((access) => (
        <AccessRow key={access.access_id} access={access} />
      ));
    }
  };

  const renderMerchant = () => {
    if (Array.isArray(merchants)) {
      return merchants.map((merchant) => (
        <option value={merchant_id}>{merchant.name}</option>
      ));
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Accesses" callback={handleChange} />
      <div className="card p-3">
        <div className="row mb-3">
          <div className="col-4">
            <select
              className="form-control"
              onChange={(e) => setFilter("merchant_id", e.target.value)}
            >
              {renderMerchant()}
            </select>
          </div>
        </div>
        <HeaderRow
          headers={[
            "#ID",
            "Comercio",
            "Usuario",
            "Vehículo",
            "Estado",
            "Entrada",
            "Salida",
            "Total",
          ]}
        />
        {renderAccesses()}
      </div>
    </div>
  );
};

export default AdminAccesses;
