import React, { useContext } from "react";
import StatusBadge from "../common/StatusBadge";
import { formatMonto } from "../../utils";
import { Link } from "@reach/router";
import moment from "moment";
import ChargeRefund from "./ChargeRefund";
import { ModalContext } from "../../context/ModalContext";
import { ChargesContext } from "../../context/ChargesContext";
import { CustomerContext } from "../../context/CustomerContext";

const ChargeRow = ({ charge, showType }) => {
  const { modalComponent } = useContext(ModalContext);

  const confirmRefund = () => {
    modalComponent("Reembolsar", <ChargeRefund charge={charge} />);
  };

  const renderCustomer = () => {
    if (charge.user && charge.user !== null) {
      return (
        <td>
          <Link to={`/myadmin/customer/${charge.user.user_id}`}>
            <i className="fa fa-eye"></i> {charge.user.name}{" "}
            {charge.user.last_name}
          </Link>
        </td>
      );
    }
  };

  const isFirstCharge = () => {
    if (charge.user && charge.user !== null) {
      if (Array.isArray(charge.user.invoices))
        return charge.user.invoices.length === 1;
    }
  };

  const renderDiscountCode = () => {
    if (charge.discount && charge.discount !== null) {
      return charge.discount.code;
    }
  };

  const renderClassPackage = () => {
    if (charge.merchant && charge.merchant !== null) {
      return charge.merchant.name;
    }
  };

  const renderPaymentMethod = () => {
    let { payment_method_id, order_id } = charge;
    switch (payment_method_id) {
      case 1:
        if (charge.invoice_id) {
          return (
            <a
              href={`https://dashboard.stripe.com/invoices/${charge.invoice_id}`}
              target="_blank"
            >
              Stripe
            </a>
          );
        }
        if (charge.subscription_id !== null) {
          return (
            <a
              href={`https://dashboard.stripe.com/subscriptions/${charge.subscription_id}`}
              target="_blank"
            >
              Stripe
            </a>
          );
        }
        return (
          <a
            href={`https://dashboard.stripe.com/payments/${order_id}`}
            target="_blank"
          >
            Stripe
          </a>
        );
      case 4:
        return (
          <a
            href={`https://www.paypal.com/activity/payment/${order_id}`}
            target="_blank"
          >
            PayPal
          </a>
        );
    }
  };

  const renderType = () => {
    if (showType) {
      return (
        <td>
          {charge.invoice_id && charge.invoice_id !== null ? "Cargo" : "Compra"}
        </td>
      );
    }
  };

  return (
    <tr className="small border-bottom align-middle hover-light">
      <td>
        {charge.invoice_id ? charge.invoice_id : charge.charge_id}{" "}
        {charge.admin_enabled && <i className="fa fa fa-user-shield"></i>}{" "}
        {isFirstCharge() && <i className="fa fa-star text-warning"></i>}
      </td>
      {renderType()}
      {renderCustomer()}
      <td>{renderClassPackage()}</td>
      <td>{moment(charge.createdAt).format("DD MMM YYYY HH:mm")}</td>
      <td>
        {charge.is_gift && <i className="fas fa-gift me-1"></i>}
        {"$"}
        {formatMonto(charge.amount)} MXN
      </td>
      <td>{renderPaymentMethod()}</td>
      <td>
        <StatusBadge
          order_id={charge.order_id}
          status={charge.status}
          date={charge.updatedAt}
        />
      </td>
      <td>{renderDiscountCode()}</td>
      <td>
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={confirmRefund}
        >
          <i className="fa fa-undo small"></i>
        </button>
      </td>
    </tr>
  );
};

export default ChargeRow;
