import React, { useContext } from "react";
import Switch from "react-switch";
import { Link } from "@reach/router";
import SubscriptionOptions from "./SubscriptionOptions";
import { ProductsContext } from "../../context/ProductsContext";

const ProductForm = ({ merchant_id }) => {
  const { spinner, product, saveProduct, setPropertyProduct } =
    useContext(ProductsContext);
  const {
    title,
    price,
    available,
    sale_price,
    description,
    usage_period,
    customer_limit,
    is_subscription,
    limit_per_order,
    short_description,
    limit_per_customer,
  } = product;

  const handleSubmit = (e) => {
    e.preventDefault();
    product.merchant_id = merchant_id;
    saveProduct(product);
  };

  const addDefaultPeriod = () => {
    setPropertyProduct("subscription_period", "month");
    setPropertyProduct("subscription_interval", "1");
  };

  const renderSubscriptionOptions = () => {
    if (is_subscription) {
      return (
        <SubscriptionOptions
          product={product}
          setPropertyProduct={setPropertyProduct}
        />
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        {/*<div className="row mb-3">
          <div className="col-6">
            <label>Disponible</label>
          </div>
          <div className="col-6">
            <Switch
              checked={available}
              onChange={(checked) => setPropertyProduct("available", checked)}
            />
          </div>
        </div>*/}
        <label>Nombre</label>
        <input
          type="text"
          className="form-control mb-3"
          value={title}
          onChange={(e) => setPropertyProduct("title", e.target.value)}
        />
        {/*<label>Descripción Corta</label>
        <input
          type="text"
          className="form-control mb-3"
          value={short_description !== null ? short_description : ""}
          onChange={(e) =>
            setPropertyProduct("short_description", e.target.value)
          }
        />
        <label>Descripción</label>
        <input
          type="text"
          className="form-control mb-3"
          value={description !== null ? description : ""}
          onChange={(e) => setPropertyProduct("description", e.target.value)}
        />*/}
        <label>Precio</label>
        <input
          type="number"
          className="form-control mb-3"
          value={price}
          onChange={(e) => setPropertyProduct("price", e.target.value)}
        />
        <label>Precio de Oferta</label>
        <input
          type="text"
          className="form-control mb-3"
          value={sale_price}
          onChange={(e) => setPropertyProduct("sale_price", e.target.value)}
        />
        <label>Periodo</label>
        <select
          value={usage_period}
          className="form-control mb-3"
          onChange={(e) => setPropertyProduct("usage_period", e.target.value)}
        >
          <option>minute</option>
          <option>hour</option>
          <option>day</option>
        </select>
        {/*!product.is_special_event && (
          <div>
            <h4 className="border-bottom pb-2">Suscripción</h4>
            <div className="row mb-2">
              <div className="col-6">
                <label>¿Es cargo recurrente?</label>
              </div>
              <div className="col-6">
                <Switch
                  checked={product.is_subscription}
                  onChange={(checked) => {
                    setPropertyProduct("is_subscription", checked);
                    if (checked) addDefaultPeriod();
                  }}
                />
              </div>
            </div>
          </div>
        )*/}
        {/*renderSubscriptionOptions()*/}
        <h4 className="border-bottom pb-2">Restricciones</h4>
        <label>Límite de Clientes</label>
        <input
          type="number"
          className="form-control mb-3"
          value={customer_limit !== null ? customer_limit : ""}
          placeholder="Dejar en blanco para desactivar"
          onChange={(e) => setPropertyProduct("customer_limit", e.target.value)}
        />
        {/*<label>Límite de Compras por Cliente</label>
        <input
          type="number"
          className="form-control mb-3"
          value={limit_per_customer !== null ? limit_per_customer : ""}
          placeholder="Dejar en blanco para desactivar"
          onChange={(e) =>
            setPropertyProduct("limit_per_customer", e.target.value)
          }
        />*/}
        <label>Límite de Usos por Compra</label>
        <input
          type="number"
          className="form-control mb-3"
          value={limit_per_order !== null ? limit_per_order : ""}
          placeholder="Dejar en blanco para desactivar"
          onChange={(e) =>
            setPropertyProduct("limit_per_order", e.target.value)
          }
        />
        <div className="row">
          <div className="col-6">
            <button type="submit" className="btn btn-primary">
              {spinner ? <div className="spinner-border"></div> : "Guardar"}
            </button>
          </div>
          <div className="col-6 text-right">
            <Link to="/myadmin/videos" className="btn btn-link text-secondary">
              Cancelar
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProductForm;
