import { Router } from "@reach/router";
import React from "react";
import AnaliticasIngresos from "./analiticas/AnaliticasIngresos";
import AnaliticasInscritos from "./analiticas/AnaliticasInscritos";
import AnaliticasMensuales from "./analiticas/AnaliticasMensuales";
import AnaliticasComercios from "./analiticas/AnaliticasComercios";

const Analitica = () => {
  return (
    <div>
      <Router>
        <AnaliticasInscritos path="/inscritos" />
        <AnaliticasIngresos path="/ingresos" default />
        <AnaliticasMensuales path="/mensuales" />
        <AnaliticasComercios path="/merchants" />
      </Router>
    </div>
  );
};

export default Analitica;
