import React, { createContext, useReducer } from "react";
import AnaliticasReducer from "../reducers/AnaliticasReducer";
import AnaliticasService from "../services/AnaliticasService";
import {
  INGRESOS_RECBIDOS,
  PAQUETES_RECIBIDOS,
  INSCRITOS_RECIBIDOS,
  MENSUALES_RECIBIDOS,
} from "../types";
import { MERCHANTS_RECEIVED } from "../types/merchants";

const initialState = {
  merchants: null,
  ingresos: null,
  users: null,
};

export const AnaliticasContext = createContext(initialState);

export const AnaliticasProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AnaliticasReducer, initialState);

  const getInscritos = (start_date, end_date) => {
    AnaliticasService.getInscritos(start_date, end_date).then((res) => {
      dispatch({ type: INSCRITOS_RECIBIDOS, payload: res.data });
    });
  };

  const getComercios = (start_date, end_date) => {
    AnaliticasService.getComercios(start_date, end_date).then((res) => {
      dispatch({ type: MERCHANTS_RECEIVED, payload: res.data.merchants });
    });
  };

  const getIngresos = (start_date, end_date) => {
    AnaliticasService.getIngresos(start_date, end_date).then((res) => {
      dispatch({ type: INGRESOS_RECBIDOS, payload: res.data });
    });
  };

  const getMensuales = (start_date, end_date) => {
    AnaliticasService.getMensuales(start_date, end_date).then((res) => {
      const { charges, users } = res.data;
      dispatch({
        type: MENSUALES_RECIBIDOS,
        payload: { charges, users },
      });
    });
  };

  return (
    <AnaliticasContext.Provider
      value={{
        ...state,
        getIngresos,
        getComercios,
        getInscritos,
        getMensuales,
      }}
    >
      {children}
    </AnaliticasContext.Provider>
  );
};
