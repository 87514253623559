import React from "react";
import { CustomerProvider } from "./context/CustomerContext";
import { ModalProvider } from "./context/ModalContext";
import { PurchaseProvider } from "./context/PurchasesContext";
import { UserProvider } from "./context/UserContext";
import { DiscountsProvider } from "./context/DiscountsContext";
import { AnaliticasProvider } from "./context/AnaliticasContext";
import { InvoicesProvider } from "./context/InvoicesContext";
import { PaymentMethodsProvider } from "./context/PaymentMethodsContext";
import { StaffProvider } from "./context/StaffContext";
import { FiltersProvider } from "./context/FiltersContext";
import { ChargesProvider } from "./context/ChargesContext";
import { MerchantsProvider } from "./context/MerchantsContext";
import { ProductsProvider } from "./context/ProductsContext";
import { VehiclesProvider } from "./context/VehiclesContext";
import { LocationsProvider } from "./context/LocationsContext";
import { AccessProvider } from "./context/AccessContext";
import { combineComponents } from "./context";
import Main from "./Main";
import "./index.css";

const AppContextProvider = combineComponents([
  ModalProvider,
  UserProvider,
  StaffProvider,
  AccessProvider,
  ChargesProvider,
  FiltersProvider,
  VehiclesProvider,
  InvoicesProvider,
  CustomerProvider,
  PurchaseProvider,
  ProductsProvider,
  DiscountsProvider,
  MerchantsProvider,
  LocationsProvider,
  AnaliticasProvider,
  PaymentMethodsProvider,
]);

function App() {
  return (
    <AppContextProvider>
      <Main />
    </AppContextProvider>
  );
}

export default App;
