import React, { useEffect, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import GoogleMapsWrapper from "./GoogleMapsWrapper";
import { getAddress } from "../../utils";

const GoogleLocationMap = ({ location }) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (location && location !== null) {
      if (typeof location === "object") {
        location = getAddress(location);
      }
      handleMarker(location);
    }
  }, [location]);

  const getCenter = () => {
    if (typeof selected === "object") {
      return selected;
    }
  };

  const handleMarker = (address) => {
    getGeocode({ address }).then((results) => {
      const geocode = results[0];
      const { lat, lng } = getLatLng(geocode);
      setSelected({ lat, lng });
    });
  };

  const renderMarker = () => {
    if (selected !== null) {
      return <Marker position={selected} />;
    }
  };

  return (
    <GoogleMap
      zoom={15}
      center={getCenter()}
      mapContainerClassName="map-container"
    >
      {renderMarker()}
    </GoogleMap>
  );
};

export default ({ location }) => (
  <GoogleMapsWrapper>
    <GoogleLocationMap location={location} />
  </GoogleMapsWrapper>
);
