import React from "react";
import { Link } from "@reach/router";

const PanelNavbar = ({ user, signOut }) => {
  const renderAnaliticas = () => {
    if (user.role === "admin" || user.role === "merchant") {
      return (
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#analiticasDropdown"
            role="button"
            data-bs-toggle="collapse"
            aria-expanded="false"
            aria-controls="analiticasDropdown"
          >
            Analiticas
          </a>
          <ul id="analiticasDropdown" className="collapse collapse-menu">
            <li className="nav-item text-item">
              <Link to="/myadmin/analytics/ingresos" className="nav-link">
                Ingresos
              </Link>
            </li>
            {user.role === "admin" && (
              <li className="nav-item text-item">
                <Link to="/myadmin/analytics/inscritos" className="nav-link">
                  Inscritos
                </Link>
              </li>
            )}
            {user.role === "admin" && (
              <li className="nav-item text-item">
                <Link to="/myadmin/analytics/merchants" className="nav-link">
                  Comercios
                </Link>
              </li>
            )}
            <li className="nav-item text-item">
              <Link to="/myadmin/analytics/mensuales" className="nav-link">
                Mensuales
              </Link>
            </li>
          </ul>
        </li>
      );
    }
  };
  return (
    <nav className="bg-dark hide-mobile side-menu">
      <div className="container-fluid px-0">
        <div className="px-3">
          <Link to="/" className="navbar-brand text-white" href="#landing">
            WallGo
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="text-white">
          <ul className="side-menu-list">
            {user.role === "admin" && (
              <li className="nav-item text-item">
                <Link to="/myadmin/customers" className="nav-link">
                  Clientes
                </Link>
              </li>
            )}
            {user.role === "admin" && (
              <li className="nav-item text-item">
                <Link to="/myadmin/vehicles" className="nav-link">
                  Vehiculos
                </Link>
              </li>
            )}
            <li className="nav-item text-item">
              <Link to="/myadmin/charges" className="nav-link">
                Pagos
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/myadmin/accesses" className="nav-link">
                Accesos
              </Link>
            </li>
            {user.role === "admin" && (
              <li className="nav-item text-item">
                <Link to="/myadmin/merchants" className="nav-link">
                  Comercios
                </Link>
              </li>
            )}
            {user.role === "admin" && (
              <li className="nav-item text-item">
                <Link to="/myadmin/users" className="nav-link">
                  Usuarios
                </Link>
              </li>
            )}
            {renderAnaliticas()}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {user.name !== null ? (
                  <div>
                    {String(user.name).substr(0, 20)}
                    {String(user.name).length > 20 ? "..." : ""}
                  </div>
                ) : (
                  "Acciones"
                )}
                <i className="ms-2 fa fa-user-circle"></i>
              </a>
              <ul
                className="dropdown-menu user-menu"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <button
                    className="dropdown-item text-danger"
                    onClick={signOut}
                  >
                    Salir <i className="ms-2 fa fa-sign-out-alt"></i>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default PanelNavbar;
