import {
  SET_PRODUCT,
  CREATE_PRODUCT,
  PRODUCTS_RECEIVED,
  SET_PROPERTY_PRODUCT,
} from "../types/products";

const schema = {
  product_id: "nuevo",
  name: "",
  price: "",
};

const ProductsReducer = (state, { type, payload }) => {
  switch (type) {
    case PRODUCTS_RECEIVED:
      return { ...state, products: payload };
    case SET_PRODUCT:
      return { ...state, product: payload };
    case SET_PROPERTY_PRODUCT:
      const { key, value } = payload;
      const product = { ...state.product };
      product[key] = value;
      return { ...state, product };
    case CREATE_PRODUCT:
      return { ...state, product: schema };
    default:
      return { ...state };
  }
};
export default ProductsReducer;
