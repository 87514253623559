import React from "react";
import CustomerRow from "./CustomerRow";

const CustomersTable = ({ customers, extraFields, showCancelReason }) => {
  const renderCustomers = () => {
    if (Array.isArray(customers)) {
      if (customers.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay clientes registradas.</td>
          </tr>
        );
      }
      return customers.map((customer) => (
        <CustomerRow
          customer={customer}
          key={customer.user_id}
          extraFields={extraFields}
          showCancelReason={showCancelReason}
        />
      ));
    }
  };

  const renderExtraFields = () => {
    if (Array.isArray(extraFields)) {
      return extraFields.map((field) => <td>{field.label}</td>);
    }
    if (showCancelReason) {
      return <td>Razón</td>;
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td className="td-id">#ID</td>
            <td>Nombre</td>
            <td>Correo</td>
            <td>Teléfono</td>
            <td>Cumpleaños</td>
            <td>Valor</td>
            {renderExtraFields()}
          </tr>
        </thead>
        <tbody>{renderCustomers()}</tbody>
      </table>
    </div>
  );
};

export default CustomersTable;
