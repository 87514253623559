import React from "react";

const StatusBadge = ({ status, order_id }) => {
  const getBadgeClassName = () => {
    let className = "";
    if (["revoked", "cancelled", "failed"].includes(status)) {
      className = "bg-danger";
    } else if (
      ["success", "active", "completed", "captured"].includes(status)
    ) {
      className = "bg-success";
    } else if (
      ["pending", "on-hold", "authorized"].includes(status) &&
      order_id !== null
    ) {
      className = "bg-warning text-dark";
    } else if (order_id === null) {
      className = "bg-danger";
    } else {
      className = "bg-secondary";
    }
    return className;
  };

  const getBadgeText = () => {
    if (order_id === null && status === "pending") {
      return "Rechazada";
    }
    if (["success", "completed"].includes(status)) {
      return "Exitosa";
    }
    if (status === "active") {
      return "Activa";
    }
    if (status === "revoked") {
      return "Revocada";
    }
    return status;
  };

  return (
    <span className={`text-capitalize badge badge-pill ${getBadgeClassName()}`}>
      {getBadgeText()}
    </span>
  );
};
export default StatusBadge;
