import { getArgs } from "../utils";
import api from "./api";

const route = "/staff";

export default {
  getAllStaff: (filters) => api.get(`${route}/admin/all?${getArgs(filters)}`),
  postStaff: (staff) => api.post(route, { ...staff }),
  putStaff: (staff) => api.put(route, { ...staff }),
  deleteStaff: (staff_id) => api.delete(`${route}/${staff_id}`),
};
