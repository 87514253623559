import React, { createContext, useContext, useReducer } from "react";
import ChargesReducer from "../reducers/ChargesReducer";
import ChargesService from "../services/ChargesService";
import { SET_CHARGE, CHARGES_RECEIVED } from "../types/charges";
import { MERCHANTS_RECEIVED } from "../types/merchants";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  spinner: false,
  charges: null,
  charge: null,
};

export const ChargesContext = createContext(initialState);

export const ChargesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ChargesReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getAllCharges = (params) => {
    ChargesService.getAllCharges(params).then((res) => {
      const { charges } = res.data;
      dispatch({ type: CHARGES_RECEIVED, payload: charges });
    });
  };

  const getCharges = () => {
    ChargesService.getCharges().then((res) => {
      const { charges, merchants } = res.data;
      dispatch({ type: MERCHANTS_RECEIVED, payload: merchants });
      dispatch({ type: CHARGES_RECEIVED, payload: charges });
    });
  };

  const getSingleCharge = (charge_id) => {
    ChargesService.getSingleCharge(charge_id).then((res) => {
      const { charge } = res.data;
      dispatch({ type: SET_CHARGE, payload: charge });
    });
  };

  const refundCharge = (charge_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    ChargesService.refundCharge(charge_id)
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        success("Cargo reembolsado.");
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <ChargesContext.Provider
      value={{
        ...state,
        getCharges,
        getAllCharges,
        getSingleCharge,
        refundCharge,
      }}
    >
      {children}
    </ChargesContext.Provider>
  );
};
