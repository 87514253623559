import React, { useEffect, useContext, useState } from "react";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import Chart from "react-apexcharts";
import moment from "moment";
import { formatMonto } from "../../utils";
import { UserContext } from "../../context/UserContext";

const AnaliticasMensuales = () => {
  const [fechaInicio, setFechaInicio] = useState(
    moment().startOf("year").format("YYYY-MM-DD")
  );
  const [fechaFin, setFechaFin] = useState(
    moment().endOf("year").format("YYYY-MM-DD")
  );

  const { user } = useContext(UserContext);
  const { charges, users, getMensuales } = useContext(AnaliticasContext);

  useEffect(() => {
    getMensuales(fechaInicio, fechaFin);
  }, [fechaInicio, fechaFin]);

  const renderIngresosMensuales = () => {
    if (charges && charges !== null) {
      const data = new Array(12).fill(1);
      data.forEach((one, index) => {
        const month = charges.find((mes) => mes.month === index + 1);
        if (month) {
          data[index] = {
            total: month.total,
            charges: month.charges,
            mes: moment(index + 1, "MM").format("MMM"),
          };
        } else {
          data[index] = {
            total: 0,
            charges: 0,
            mes: moment(index + 1, "MM").format("MMM"),
          };
        }
      });
      return (
        <Chart
          type="area"
          options={{
            colors: ["#094f7f", "#f84f10", "#c6c0c1", "#000"],
            dataLabels: {
              formatter: (val, opts) => {
                if (opts.seriesIndex === 0) {
                  return `$${formatMonto(val)}`;
                }
                return val;
              },
            },
            xaxis: {
              categories: [
                "Ene",
                "Feb",
                "Mar",
                "Abr",
                "May",
                "Jun",
                "Jul",
                "Ago",
                "Sep",
                "Oct",
                "Nov",
                "Dic",
              ],
            },
          }}
          series={[
            { name: "Ingresos por Mes", data: data.map(({ total }) => total) },
            {
              name: "Cargos por Mes",
              data: data.map(({ charges }) => charges),
            },
          ]}
        />
      );
    }
  };

  const renderUsers = () => {
    if (Array.isArray(users)) {
      const data = new Array(12).fill(1);
      data.forEach((one, index) => {
        const month = users.find((mes) => mes.month === index + 1);
        console.log(users);
        if (month) {
          data[index] = {
            users: month.users,
            mes: moment(index + 1, "MM").format("MMM"),
          };
        } else {
          data[index] = {
            users: 0,
            mes: moment(index + 1, "MM").format("MMM"),
          };
        }
      });
      return (
        <Chart
          type="area"
          options={{
            colors: ["#094f7f", "#f84f10", "#c6c0c1", "#000"],
            xaxis: {
              categories: [
                "Ene",
                "Feb",
                "Mar",
                "Abr",
                "May",
                "Jun",
                "Jul",
                "Ago",
                "Sep",
                "Oct",
                "Nov",
                "Dic",
              ],
            },
          }}
          series={[
            {
              name: "Usuarios por Mes",
              data: data.map(({ users }) => users),
            },
          ]}
        />
      );
    }
  };

  return (
    <div className="container-fluid">
      <div className="row border-bottom pb-3 mb-3">
        <div className="col-6">
          <h1>Analíticas Mensuales</h1>
        </div>
        <div className="col-6">
          <div className="row mx-0">
            <div className="col-6">
              <input
                type="date"
                className="form-control ms-2"
                value={fechaInicio}
                onChange={(e) =>
                  setFechaInicio(moment(e.target.value).format("YYYY-MM-DD"))
                }
              />
            </div>
            <div className="col-6">
              <input
                type="date"
                className="form-control ms-2"
                value={fechaFin}
                onChange={(e) =>
                  setFechaFin(moment(e.target.value).format("YYYY-MM-DD"))
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-xl-6">
          <div className="card mb-3 border shadow-sm p-3  overflow-x-hidden">
            <p className="bold">Ingresos por Mes</p>
            {renderIngresosMensuales()}
          </div>
        </div>
        <div className="col-md-12 col-xl-6">
          {user.role === "admin" && (
            <div className="card mb-3 border shadow-sm p-3  overflow-x-hidden">
              <p className="bold">Usuarios por Mes</p>
              {renderUsers()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnaliticasMensuales;
