import React from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import GoogleMapsWrapper from "./GoogleMapsWrapper";

const MapsAutocomplete = ({ value, modifier, handleSelect, placeholder }) => {
  const { ready, setValue, suggestions, clearSuggestions } =
    usePlacesAutocomplete();

  const renderSuggestions = () => {
    if (suggestions.data.length > 0) {
      return (
        <ul className="dropdown-menu d-block">
          {suggestions.data.map((location) => (
            <li
              key={location.place_id}
              className="px-3 py-1 border-bottom hover-light"
              onClick={() => {
                handleSelect(location.description);
                clearSuggestions();
              }}
            >
              {location.description}
            </li>
          ))}
        </ul>
      );
    }
  };

  return (
    <div className="dropdown show mb-3">
      <input
        type="text"
        value={value}
        disabled={!ready}
        placeholder={placeholder}
        className="form-control"
        onChange={(e) => {
          setValue(e.target.value);
          modifier(e.target.value);
        }}
      />
      {renderSuggestions()}
    </div>
  );
};

export default (props) => (
  <GoogleMapsWrapper>
    <MapsAutocomplete {...props} />
  </GoogleMapsWrapper>
);
