import React, { useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import { ModalContext } from "../../context/ModalContext";
import PanelTitle from "../../components/global/PanelTitle";
import { CustomerContext } from "../../context/CustomerContext";
import ChargesTable from "../../components/charges/ChargesTable";
import CustomerData from "../../components/customers/CustomerData";
import VehiclesTable from "../../components/vehicles/VehiclesTable";
import PurchasesTable from "../../components/purchases/PurchasesTable";
import CustomerActions from "../../components/customers/CustomerActions";
import { navigate } from "@reach/router";
import { hideModal } from "../../utils";

const AdminSingleUsuario = ({ customer_id }) => {
  const { customer, getCustomer, clearCustomer, deleteCustomer } =
    useContext(CustomerContext);

  const { modalComponent } = useContext(ModalContext);

  const { user } = useContext(UserContext);

  useEffect(() => {
    getCustomer(customer_id);
    //getCourses();
    return clearCustomer;
  }, []);

  const confirmDelete = () => {
    modalComponent(
      "Precaución",
      <div>
        <p>
          ¿Estás segura que deseas eliminar el cliente {customer.name}{" "}
          {customer.last_name} con el correo {customer.email}?
        </p>
        <p>Esta acción NO puede deshacerse.</p>
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-danger"
              onClick={() => deleteCustomer(customer_id)}
            >
              <i className="fa fa-trash"></i> Eliminar Cliente
            </button>
          </div>
          <div className="col-6 text-right">
            <button className="btn btn-link text-secondary" onClick={hideModal}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderUsuario = () => {
    if (customer && customer !== null) {
      return (
        <div className="row">
          <div className="col-12 col-md-6">
            <CustomerData
              customer={customer}
              handleEdit={() =>
                navigate(`/myadmin/customer/${customer.user_id}/edit`)
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomerActions customer={customer} />
          </div>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid">
      <div className="row pb-2 border-bottom mx-0 mb-3 align-items-center">
        <div className="col col-md-6 ps-0">
          <h1>Cliente</h1>
        </div>
        <div className="col col-md-6 pe-0 text-end"></div>
      </div>
      <div className="card  mb-3">{renderUsuario()}</div>
      <div className="card p-3 pb-1  my-3">
        <PanelTitle title="Compras" hideButton />
        <PurchasesTable
          user={user}
          purchases={customer !== null ? customer.purchases : []}
        />
      </div>
      <div className="card p-3  my-3">
        <PanelTitle title="Vehiculos" hideButton />
        <VehiclesTable
          hideUser={true}
          vehicles={customer !== null ? customer.vehicles : []}
        />
      </div>
      <div className="card p-3  my-3">
        <PanelTitle title="Cargos" hideButton />
        <ChargesTable
          charges={customer !== null ? customer.charges : []}
          user={user}
        />
      </div>
      <button className="btn btn-outline-danger mt-5" onClick={confirmDelete}>
        <i className="fa fa-trash"></i> Eliminar Cliente
      </button>
    </div>
  );
};

export default AdminSingleUsuario;
