import { SHOW_SUCCESS, SHOW_ALERT, CLEAR_ALERT, CLEAR_SUCCESS } from "../types";
import moment from "moment";

moment.locale("es", {
  monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
  weekdaysShort: "Dom_Lun_Mar_Mie_Jue_Vie_Sab".split("_"),
});

export const BASE_URL =
  (process.env.NODE_ENV === "development"
    ? "http://localhost:4000"
    : "https://wallgoapp.com") + "/api";

export const S3_ENDPOINT = "https://wallgoapp.s3.us-west-1.amazonaws.com";

const stripe_dev_key = "";

const stripe_live_key = "";

export const STRIPE_KEY =
  process.env.NODE_ENV === "development" ? stripe_dev_key : stripe_live_key;

export const GOOGLE_MAPS_KEY = "AIzaSyBnngxJGKf-SupSroc6REhIsUVIrBxxB8U";

export const displaySuccess = (dispatch, message) => {
  dispatch({ type: SHOW_SUCCESS, payload: message });
  setTimeout(() => {
    dispatch({ type: CLEAR_SUCCESS });
  });
};

export const displayError = (dispatch, error) => {
  if (typeof error === "object") {
    error = error.toString();
  }
  dispatch({ type: SHOW_ALERT, payload: error });
  setTimeout(() => dispatch({ type: CLEAR_ALERT }), 3000);
};

export const searchRows = (query, rows) => {
  if (!rows) return;
  if (isNaN(query)) query = query.toLowerCase();
  let searchResult = rows.filter((row) => {
    let result = Object.keys(row).filter((column) => {
      if (Array.isArray(row[column])) {
        return row[column].filter((subcolumn) => {
          if (isNaN(subcolumn)) {
            if (subcolumn.toLowerCase().includes(query)) return row;
          } else if (subcolumn === query) return row;
          return null;
        });
      }
      if (isNaN(row[column])) {
        if (String(row[column]).toLowerCase().includes(query)) {
          return row;
        }
      } else if (String(row[column]) === query) {
        return row;
      } else if (Number(row[column]) === Number(query)) {
        return row;
      }
      return null;
    });
    return result.length > 0;
  });
  return searchResult;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getArgs = (args) => {
  if (args && args !== null) {
    const array = Object.keys(args)
      .map((key) => {
        if (args[key] && args[key] !== null && args[key] !== "") {
          return `${key}=${args[key]}`;
        }
        return null;
      })
      .filter((arg) => arg !== null);
    if (array.length > 0) {
      return `${array.join("&")}`;
    }
  }
  return "";
};

export const calcularTotal = (productos) => {
  if (productos && productos !== null) {
    let total = 0;
    productos.forEach((producto) => {
      total += producto.precio * producto.cantidad;
    });
    return total;
  }
  return 0;
};

export function formatMonto(monto) {
  monto = parseFloat(monto);
  if (!monto || monto === null || isNaN(monto)) monto = 0;
  return numberWithCommas(monto);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const hideModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  }
};

export const hideMobileMenu = () => {
  const menu = document.getElementsByClassName("drawer-menu")[0];
  if (menu && menu !== null) {
    menu.style.left = "-100vw";
  }
};

export const showModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  } else {
    const newButton = document.createElement("button");
    newButton.attributes.href = "#modal";
    newButton.id = "main-button";
    newButton.setAttribute("data-toggle", "modal");
    newButton.setAttribute("data-target", "#modal");
    newButton.setAttribute("data-bs-toggle", "modal");
    newButton.setAttribute("data-bs-target", "#modal");
    newButton.style.visibility = "hidden";
    document.body.appendChild(newButton);
    newButton.click();
  }
};

export const adminitems = [
  {
    name: "Ventas",
    handle: "/myadmin/orders",
  },
  {
    name: "Clientes",
    handle: "/myadmin/customers",
  },
  {
    name: "Cursos",
    handle: "/myadmin/courses",
  },
  {
    name: "Instructores",
    handle: "/myadmin/instructors",
  },
  {
    name: "Tutorials",
    handle: "/myadmin/sections/4",
  },
  {
    name: "Marketing",
    handle: "/myadmin/sections/5",
  },

  {
    name: "Documents",
    handle: "/myadmin/sections/6",
  },
  {
    name: "Music",
    handle: "/myadmin/sections/7",
  },
  {
    name: "Usuarios",
    handle: "/myadmin/usuarios",
  },
];

export const analiticasitems = [
  {
    name: "Inscritos",
    handle: "/myadmin/analytics/inscritos",
  },
  {
    name: "Ingresos",
    handle: "/myadmin/analytics/ingresos",
  },
  {
    name: "Cursos",
    handle: "/myadmin/analytics/merchants",
  },
  {
    name: "Mensuales",
    handle: "/myadmin/analytics/mensuales",
  },
];

export const randomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getCardType = (number) =>
  String(number) === "3"
    ? "amex"
    : String(number) === "4"
    ? "visa"
    : "mastercard";

export const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const paymentMethods = [
  {
    name: "Tarjeta de Crédito Online",
    value: 1,
  },
  {
    name: "PayPal",
    value: 2,
  },
  {
    name: "Transferencia y Depósito",
    value: 3,
  },
  {
    name: "Efectivo",
    value: 5,
  },
  {
    name: "Terminal PDV",
    value: 7,
  },
  {
    name: "Otros métodos de pago",
    value: 6,
  },
];

export const getFormDataFile = (file, key) => {
  const formData = new FormData();
  formData.append(key, file);
  return formData;
};

export const getTotalCompras = (customer) => {
  let total = 0;
  if (Array.isArray(customer.invoices)) {
    customer.invoices
      .filter((invoice) => ["completed", "success"].includes(invoice.status))
      .forEach((purchase) => {
        total += parseFloat(purchase.amount);
      });
  }
  if (Array.isArray(customer.purchases)) {
    customer.purchases
      .filter((purchase) => ["completed", "success"].includes(purchase.status))
      .forEach((purchase) => {
        if (purchase.subscription_id === null) {
          total += parseFloat(purchase.amount);
        }
      });
  }
  if (Array.isArray(customer.charges)) {
    customer.charges
      .filter((purchase) =>
        ["captured", "completed", "success"].includes(purchase.status)
      )
      .forEach((charge) => {
        total += parseFloat(charge.amount);
      });
  }
  return total;
};

//Converts a UTC String date from server response to UTC moment Date
export const convertServerStringDateToUTC = (dateString) =>
  moment.utc(dateString, "YYYY-MM-DD HH:mm:ss");

//Converts Moment UTC Date to Moment Local Date
export const convertMomentUTCToLocal = (utcDate) => utcDate.local();

export const convertToUtcString = (dateString) =>
  moment.utc(dateString).format("YYYY-MM-DD HH:mm:ss");

export const displayLocalDateTime = (dateString) =>
  convertMomentUTCToLocal(moment(dateString)).format("YYYY-MM-DD HH:mm:ss");

export const displayDateTime = (dateString) =>
  convertServerStringDateToUTC(dateString).format("YYYY-MM-DD HH:mm:ss");

export const displayTime = (dateString) =>
  convertMomentUTCToLocal(convertServerStringDateToUTC(dateString)).format(
    "HH:mm"
  );

export const displayDayTime = (dateString) =>
  convertServerStringDateToUTC(dateString).format("ddd DD, HH:mm");

export const displayDayTimeMonth = (dateString) =>
  convertServerStringDateToUTC(dateString).format("ddd DD MMM, HH:mm");

export const getFilters = (lessons) => {
  let instructorSet = new Set();
  let authorSet = new Set();
  let levelSet = new Set();
  lessons.forEach((lesson) => {
    const { instructor_id, author, level } = lesson;
    if (instructor_id !== "" && instructor_id !== null) {
      instructorSet.add(lesson.instructor_id);
    }
    if (author !== "" && author !== null) {
      authorSet.add(author);
    }
    if (level !== "" && level !== null) {
      levelSet.add(level);
    }
  });
  let instructorArray = Array.from(instructorSet);
  let instructors = [];
  instructorArray.forEach((instructor_id) => {
    let lesson = lessons.find(
      (lesson) => lesson.instructor_id === instructor_id
    );
    let instructor = lesson.instructor;
    instructors.push(instructor);
  });
  instructors = instructors.map((instructor) => ({
    name: instructor.name,
    value: instructor.instructor_id,
  }));
  let authors = Array.from(authorSet).map((author) => ({
    name: author,
    value: author,
  }));
  let levels = Array.from(levelSet).map((level) => ({
    name: level,
    value: level,
  }));
  return { instructors, levels, authors };
};

export const getValue = (object, key) => {
  if (object && object !== null) {
    const value = object[key];
    if (value && value !== null) {
      return value;
    }
  }
  return "";
};

export const getAddress = (location) => {
  console.log(location);
  if (location === null) return "";
  return `${location.street} ${location.number} ${location.city}, ${location.state}`;
};
