import React, { createContext, useReducer, useContext } from "react";
import MerchantsService from "../services/MerchantsService";
import {
  CREATE_MERCHANT,
  MERCHANTS_RECEIVED,
  SET_PROPERTY_MERCHANT,
  SINGLE_MERCHANT_RECEIVED,
} from "../types/merchants";
import MerchantsReducer from "../reducers/MerchantsReducer";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import AddressesService from "../services/AddressService";

const initialState = {
  merchants: null,
  merchant: null,
  spinner: false,
};

export const MerchantsContext = createContext(initialState);

export const MerchantsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MerchantsReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getAllMerchants = (filters) => {
    MerchantsService.getAllMerchants(filters).then((res) => {
      const { merchants } = res.data;
      dispatch({ type: MERCHANTS_RECEIVED, payload: merchants });
    });
  };

  const getSingleMerchant = (merchant_id) => {
    MerchantsService.getSingleMerchant(merchant_id).then((res) => {
      const { merchant } = res.data;
      dispatch({ type: SINGLE_MERCHANT_RECEIVED, payload: merchant });
    });
  };

  const saveMerchant = (merchant) => {
    dispatch({ type: SHOW_SPINNER });
    let service = MerchantsService.putMerchant;
    if (isNaN(parseInt(merchant.merchant_id))) {
      service = MerchantsService.postMerchant;
    }
    const promises = [];
    if (merchant.address_id === null && merchant.address !== null) {
      promises.push(
        new Promise((resolve, reject) => {
          AddressesService.postAddress(merchant.address).then((res) => {
            merchant.address_id = res.data.address.address_id;
            resolve();
          });
        })
      );
    } else if (merchant.address_id !== null && merchant.address !== null) {
      promises.push(AddressesService.putAddress(merchant.address));
    }
    Promise.all(promises).then(() => {
      service(merchant)
        .then(() => {
          dispatch({ type: HIDE_SPINNER });
          success("Merchant saved.");
          getAllMerchants();
          clearModal();
        })
        .catch((error) => {
          dispatch({ type: HIDE_SPINNER });
          alert(error);
        });
    });
  };

  const createMerchant = () => {
    dispatch({ type: CREATE_MERCHANT });
  };

  const setMerchant = (merchant) => {
    dispatch({ type: SINGLE_MERCHANT_RECEIVED, payload: merchant });
  };

  const setPropertyMerchant = (key, value) => {
    dispatch({ type: SET_PROPERTY_MERCHANT, payload: { key, value } });
  };

  const deleteMerchant = (merchant_id) => {
    dispatch({ type: SHOW_SPINNER });
    MerchantsService.deleteMerchant(merchant_id)
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        success("Merchant deleted.");
        getAllMerchants();
        clearModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <MerchantsContext.Provider
      value={{
        ...state,
        setMerchant,
        saveMerchant,
        createMerchant,
        deleteMerchant,
        getAllMerchants,
        getSingleMerchant,
        setPropertyMerchant,
      }}
    >
      {children}
    </MerchantsContext.Provider>
  );
};
