import React, { useContext } from "react";
import { formatMonto } from "../../utils";
import { ChargesContext } from "../../context/ChargesContext";
import { CustomerContext } from "../../context/CustomerContext";

const ChargeRefund = ({ charge }) => {
  const { getCustomer } = useContext(CustomerContext);
  const { spinner, refundCharge } = useContext(ChargesContext);

  const handleCallback = () => getCustomer(charge.user.user_id);

  return (
    <div>
      <p>
        ¿Estás seguro que deseas reembolsar el cargo {charge.charge_id} por $
        {formatMonto(charge.amount)}?
      </p>
      <p>Esta acción NO puede deshacerse.</p>
      <button
        disabled={spinner}
        className="btn btn-danger"
        onClick={() => refundCharge(charge.charge_id, handleCallback)}
      >
        <i className="fa fa-undo me-1"></i>{" "}
        {spinner ? <div className="spinner-border"></div> : "Reembolsar"}
      </button>
    </div>
  );
};

export default ChargeRefund;
