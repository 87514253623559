import React, { useEffect, useContext } from "react";
import HeaderRow from "../../components/global/HeaderRow";
import { ModalContext } from "../../context/ModalContext";
import { MerchantsContext } from "../../context/MerchantsContext";
import MerchantForm from "../../components/merchants/MerchantForm";
import MerchantRow from "../../components/merchants/MerchantRow";

const AdminMerchants = () => {
  const {
    merchants,
    setMerchant,
    saveMerchant,
    createMerchant,
    deleteMerchant,
    getAllMerchants,
    setPropertyMerchant,
  } = useContext(MerchantsContext);

  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getAllMerchants();
  }, []);

  const editMerchant = (merchant) => {
    setMerchant(merchant);
    modalComponent(
      "Editar Comercio",
      <MerchantForm
        modifier={setPropertyMerchant}
        postMerchant={saveMerchant}
      />
    );
  };

  const addMerchant = () => {
    createMerchant();
    modalComponent(
      "Agregar Comercio",
      <MerchantForm
        modifier={setPropertyMerchant}
        postMerchant={saveMerchant}
      />
    );
  };

  const confirmDelete = (merchant) => {
    modalComponent(
      "Precaución",
      <div className="container-fluid px-0">
        <p>
          ¿Estás segura que deseas eliminar el comercio {merchant.name}? Esta
          acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => deleteMerchant(merchant.merchant_id)}
        >
          Eliminar
        </button>
      </div>
    );
  };

  const renderMechants = () => {
    if (Array.isArray(merchants)) {
      return merchants.map((merchant) => (
        <MerchantRow
          key={merchant.merchant_id}
          merchant={merchant}
          handleEdit={editMerchant}
          handleDelete={confirmDelete}
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid">
      <div className="row border-bottom pb-3 mb-3">
        <div className="col col-md-6">
          <h1>Comercios</h1>
        </div>
        <div className="col col-md-6 text-end">
          <button className="btn btn-dark" onClick={addMerchant}>
            + Agregar
          </button>
        </div>
      </div>
      <HeaderRow headers={["Nombre", "Dirección", "Acciones"]} />
      {renderMechants()}
    </div>
  );
};

export default AdminMerchants;
