import React, { useContext, useState } from "react";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import CustomersTable from "../../components/customers/CustomersTable";
import { formatMonto } from "../../utils";

const AnaliticasInscritos = () => {
  const [viewCustomers, setViewCustomers] = useState(null);
  const { arpu, ltv, nuevos, activos, getInscritos } =
    useContext(AnaliticasContext);

  const renderCustomers = () => {
    if (viewCustomers !== null) {
      let customersRender = [];
      switch (viewCustomers) {
        case "nuevos":
          customersRender = nuevos;
          break;
        default:
          customersRender = activos;
      }
      return (
        <div className="card p-3 ">
          {" "}
          <div className="row align-items-center mb-2">
            <div className="col-12 col-md-6">
              <h3 className="mb-0">
                Clientes{" "}
                <span className="text-capitalize">{viewCustomers}</span>
              </h3>
            </div>
            <div className="col-12 col-md-6 text-right">
              <button
                className="btn btn-link text-secondary"
                onClick={() => setViewCustomers(null)}
              >
                Ocultar
              </button>
            </div>
          </div>
          <CustomersTable customers={customersRender} />
        </div>
      );
    }
  };

  const renderAmount = (data) => {
    if (Array.isArray(data)) {
      return data.length;
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid px-3 mb-3">
      <PanelTitleDate title="Inscritos" callback={getInscritos} />
      <div className="row my-4">
        <div className="col-6">
          <div className="card text-center p-3">
            <h3 className="h1">${formatMonto(arpu)} MXN</h3>
            <p className="mb-0">ARPU</p>
          </div>
        </div>
        <div className="col-6">
          <div className="card text-center p-3">
            <h3 className="h1">${formatMonto(ltv)} MXN</h3>
            <p className="mb-0">LTV</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 my-2">
          <div className="card p-3 ">
            <p className="bold">Usuarios Registrados este periodo</p>
            <h3 className="mb-0">{renderAmount(nuevos)}</h3>
            <button
              className="btn btn-link text-secondary text-left px-0 my-2"
              onClick={() => setViewCustomers("nuevos")}
            >
              Expandir
            </button>
          </div>
        </div>
        <div className="col-12 col-md-6 my-2">
          <div className="card p-3  mb-4">
            <p className="bold">Usuarios Activos este periodo</p>
            <h3 className="mb-0">{renderAmount(activos)}</h3>
            <button
              className="btn btn-link text-secondary text-left px-0 my-2"
              onClick={() => setViewCustomers("activos")}
            >
              Expandir
            </button>
          </div>
        </div>
      </div>

      {renderCustomers()}
    </div>
  );
};

export default AnaliticasInscritos;
