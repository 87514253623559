import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  CREATE_MERCHANT,
  MERCHANTS_RECEIVED,
  SET_PROPERTY_MERCHANT,
  SINGLE_MERCHANT_RECEIVED,
} from "../types/merchants";

const schema = {
  merchant_id: "nuevo",
  name: "",
};

const MerchantsReducer = (state, { type, payload }) => {
  switch (type) {
    case MERCHANTS_RECEIVED:
      return { ...state, merchants: payload };
    case SINGLE_MERCHANT_RECEIVED:
      return { ...state, merchant: payload };
    case SET_PROPERTY_MERCHANT:
      const merchant = { ...state.merchant };
      const { key, value } = payload;
      merchant[key] = value;
      return { ...state, merchant };
    case CREATE_MERCHANT:
      return { ...state, merchant: schema };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
export default MerchantsReducer;
