import React, { createContext, useReducer } from "react";
import AccessReducer from "../reducers/AccessReducer";
import AccessesService from "../services/AccessesService";
import { ACCESSES_RECEIVED } from "../types/access";

const initialState = {
  accesses: null,
  access: null,
};

export const AccessContext = createContext(initialState);

export const AccessProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AccessReducer, initialState);

  const getAccesses = (filters) => {
    AccessesService.getAccesses(filters).then((res) => {
      const { accesses } = res.data;
      dispatch({ type: ACCESSES_RECEIVED, payload: accesses });
    });
  };

  return (
    <AccessContext.Provider value={{ ...state, getAccesses }}>
      {children}
    </AccessContext.Provider>
  );
};
