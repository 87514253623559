import React, { useContext } from "react";
import HeaderRow from "../../components/global/HeaderRow";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import { formatMonto } from "../../utils";
import Chart from "react-apexcharts";

const AnaliticasComercios = () => {
  const { merchants, getComercios } = useContext(AnaliticasContext);

  const renderChart = () => {
    if (Array.isArray(merchants)) {
      let globalTotal = 0;
      merchants.forEach((paquete) => {
        globalTotal += parseFloat(paquete.total);
      });
      return (
        <Chart
          type="donut"
          height="415"
          width="100%"
          options={{
            labels: merchants.map(({ name }) => name),
          }}
          series={merchants.map(({ total }) => parseFloat(total) / globalTotal)}
        />
      );
    }
  };

  const renderMerchants = () => {
    if (merchants && merchants !== null) {
      return merchants.map((paquete) => (
        <div className="row p-2" key={paquete.course_id}>
          <div className="col">{paquete.name}</div>
          <div className="col">{paquete.charges}</div>
          <div className="col">
            {"$"}
            {formatMonto(paquete.total)}
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Comercios" callback={getComercios} />
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card shadow-sm p-3 me-3  my-3">
            <h4>Cargos por Comercio</h4>
            <HeaderRow headers={["Nombre", "Compras", "Total"]} />
            {renderMerchants()}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card shadow-sm p-3 me-3  my-3">
            <h4>Ingresos por Comercio</h4>
            {renderChart()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnaliticasComercios;
