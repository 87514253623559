import {
  CREATE_VEHICLE,
  SET_PROPERTY_VEHICLE,
  SET_VEHICLE,
  VEHICLES_RECEIVED,
} from "../types/vehicles";

const VehiclesReducer = (state, { type, payload }) => {
  switch (type) {
    case VEHICLES_RECEIVED:
      return { ...state, vehicles: payload };
    case SET_VEHICLE:
      return { ...state, vehicle: payload };
    default:
      return { ...state };
  }
};
export default VehiclesReducer;
