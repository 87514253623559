import api from "./api";
import { getArgs } from "../utils";

const route = "/merchants";

const MerchantsService = {
  getAllMerchants: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getSingleMerchant: (merchant_id) => api.get(`${route}/${merchant_id}`),
  postMerchant: (merchant) => api.post(route, { ...merchant }),
  putMerchant: (merchant) => api.put(route, { ...merchant }),
  deleteMerchant: (merchant_id) => api.delete(`${route}/${merchant_id}`),
};
export default MerchantsService;
