import api from "./api";
import { getArgs } from "../utils";

const route = "/charges";

const ChargesService = {
  getCharges: () => api.get(route),
  getAllCharges: (params) => api.get(`${route}/admin/all?${getArgs(params)}`),
  getSingleCharge: (charge_id) => api.get(`${route}/${charge_id}`),
  refundCharge: (charge_id) => api.delete(`${route}/${charge_id}`),
};
export default ChargesService;
