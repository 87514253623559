import { Link } from "@reach/router";
import moment from "moment";
import React from "react";
import { formatMonto } from "../../utils";

const AccessRow = ({ access }) => {
  const renderMechant = () => {
    const { merchant } = access;
    if (merchant && merchant !== null) {
      return merchant.name;
    }
  };

  const renderUserName = () => {
    const { charge } = access;
    if (charge && charge !== null) {
      const { user } = charge;
      if (user && user !== null) {
        return (
          <Link to={`/myadmin/customer/${user.user_id}`}>
            <i className="fa fa-user me-1"></i> {user.name} {user.last_name}
          </Link>
        );
      }
    }
  };

  const renderVehicle = () => {
    const { vehicle } = access;
    if (vehicle && vehicle !== null) {
      return vehicle.model;
    }
  };

  const renderAmount = () => {
    const { charge } = access;
    if (charge && charge !== null) {
      return `$${formatMonto(access.charge.amount)}`;
    }
  };

  return (
    <div className="row p-2 border-bottom align-items-center">
      <div className="col">#{access.access_id}</div>
      <div className="col">{renderMechant()}</div>
      <div className="col">{renderUserName()}</div>
      <div className="col">{renderVehicle()}</div>
      <div className="col">{access.status}</div>
      <div className="col">
        {moment(access.createdAt).utc().format("DD MMM YYYY HH:mm")}
      </div>
      <div className="col">
        {moment(access.updatedAt).utc().format("DD MMM YYYY HH:mm")}
      </div>
      <div className="col">{renderAmount()}</div>
    </div>
  );
};

export default AccessRow;
