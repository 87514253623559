import { Link } from "@reach/router";
import React from "react";

const MerchantRow = ({ merchant, handleEdit, handleDelete }) => {
  return (
    <div key={merchant.merchant_id} className="card px-3 py-1 small">
      <div className="row align-items-center">
        <div className="col col-md-4">
          <Link to={`/myadmin/merchants/${merchant.merchant_id}`}>
            {merchant.name}
          </Link>
        </div>
        <div className="col col-md-4">{merchant.address}</div>
        <div className="col col-md-4">
          <button
            className="btn btn-sm btn-outline-dark"
            onClick={() => handleEdit(merchant)}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-danger mx-3"
            onClick={() => handleDelete(merchant)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MerchantRow;
