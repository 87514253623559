import { Link } from "@reach/router";
import React from "react";

const VehicleRow = ({ vehicle, hideUser }) => {
  return (
    <div className="row mx-0 py-2 border-bottom">
      <div className="col bold">#{vehicle.vehicle_id}</div>
      <div className="col">{vehicle.make}</div>
      <div className="col">{vehicle.model}</div>
      <div className="col">{vehicle.year}</div>
      {!hideUser && (
        <div className="col">
          <Link to={`/myadmin/customer/${vehicle.user.user_id}`}>
            <i className="fa fa-user me-1"></i> {vehicle.user.name}
          </Link>
        </div>
      )}
    </div>
  );
};

export default VehicleRow;
