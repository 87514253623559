import React, { useContext, useEffect } from "react";
import { VehiclesContext } from "../../context/VehiclesContext";
import VehicleRow from "../../components/vehicles/VehicleRow";
import HeaderRow from "../../components/global/HeaderRow";

const AdminVehicles = () => {
  const { vehicles, getVehicles } = useContext(VehiclesContext);

  useEffect(() => {
    getVehicles();
  }, []);

  const renderVehicles = () => {
    if (Array.isArray(vehicles)) {
      return vehicles.map((vehicle) => (
        <VehicleRow key={vehicle.vehicle_id} vehicle={vehicle} />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <h1>Vehículos</h1>
      <div className="card p-3 shadow">
        <HeaderRow headers={["ID", "Marca", "Modelo", "Año", "Cliente"]} />
        {renderVehicles()}
      </div>
    </div>
  );
};

export default AdminVehicles;
