import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { formatMonto } from "../../utils";
import ProductForm from "./ProductForm";
import { ProductsContext } from "../../context/ProductsContext";

const ProductRow = ({ product, handleDeleteCallback }) => {
  const { modalComponent } = useContext(ModalContext);
  const { setProduct, deleteProduct } = useContext(ProductsContext);

  const handleEdit = () => {
    setProduct(product);
    modalComponent("Editar Producto", <ProductForm />);
  };

  const handleDelete = () => {
    modalComponent(
      "Precaución",
      <div>
        <p>¿Estás seguro que deseas eliminar el producto {product.title}</p>
        <p>Esta acción NO puede deshacerse.</p>
        <button
          className="btn btn-danger"
          onClick={() =>
            deleteProduct(product.product_id, handleDeleteCallback)
          }
        >
          <i className="fa fa-trash"></i> Eliminar
        </button>
      </div>
    );
  };

  return (
    <tr className="small border-bottom align-middle hover-light">
      <td>{product.product_id}</td>
      <td>{product.title}</td>
      <td>{product.order}</td>
      <td>
        {"$"}
        {formatMonto(product.price)} MXN
      </td>
      <td>{product.usage_period}</td>
      <td>{product.limit_per_order}</td>
      <td>
        <button
          className="btn btn-sm btn-outline-dark me-2"
          onClick={handleEdit}
        >
          <i className="fa fa-edit"></i>
        </button>
        <button
          className="btn btn-sm btn-outline-danger me-2"
          onClick={handleDelete}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};

export default ProductRow;
