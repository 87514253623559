import React, { useState, useContext, useEffect } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { StaffContext } from "../../context/StaffContext";
import { ModalContext } from "../../context/ModalContext";
import { MerchantsContext } from "../../context/MerchantsContext";
import { validateEmail } from "../../utils";

const UserForm = () => {
  const [customer, setCustomer] = useState(null);
  const [role, setRole] = useState("admin");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [merchant, setMerchant] = useState(null);
  const [merchantQuery, setMerchantQuery] = useState("");
  const [disabled, setDisabled] = useState(true);

  const { customers, getAllCustomers } = useContext(CustomerContext);
  const { merchants, getAllMerchants } = useContext(MerchantsContext);

  const { clearModal } = useContext(ModalContext);

  const { createStaff } = useContext(StaffContext);

  useEffect(() => {
    if (query !== "") {
      getAllCustomers({ query });
    }
  }, [query]);

  useEffect(() => {
    if (customer !== null || email !== "") {
      setDisabled(false);
    }
  }, [customer, email]);

  useEffect(() => {
    if (role === "merchant" || merchantQuery !== "") {
      getAllMerchants({ query: merchantQuery });
    }
  }, [merchantQuery, role]);

  useEffect(() => {
    if (customer !== null) {
      if (customer.email === null || customer.email === "") {
        setDisabled(true);
      } else {
        setEmail(customer.email);
      }
    } else {
      setEmail("");
    }
  }, [customer]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = { email, role };
    if (merchant && merchant !== null) {
      payload.merchant_id = merchant.merchant_id;
    }
    if (customer && customer !== null) {
      payload.user_id = customer.user_id;
    }
    createStaff(payload);
  };

  const renderCustomers = () => {
    if (customer !== null) {
      return (
        <div className="card p-2 mb-3 border">
          <div className="row mx-0 small align-items-center">
            <div className="col">{customer.name}</div>
            <div className="col">{customer.email}</div>
            <div className="col text-right">
              <button
                className="btn btn-outline-danger btn-sm"
                onClick={() => setCustomer(null)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          </div>
          {disabled && (
            <p className="text-danger">
              El usuario necesita un correo electrónico para ser Staff
            </p>
          )}
        </div>
      );
    }
    if (email !== "") {
      return (
        <div className="card p-2 border mb-3">
          <div className="row align-items-center mx-0">
            <div className="col-6">{email}</div>
            <div className="col-6 text-end">
              <button className="btn btn-sm btn-outline-danger">
                <i className="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      );
    }
    if (Array.isArray(customers)) {
      return (
        <div>
          {validateEmail(query) && (
            <button
              className="btn btn-outline-primary w-100 mb-3"
              onClick={() => setEmail(query)}
            >
              Invitar a {query}
            </button>
          )}
          {customers.map((customer) => (
            <div
              key={customer.user_id}
              className="row align-items-center py-2 small hover-light border-top"
            >
              <div className="col col-md-4">
                {customer.name} {customer.last_name}
              </div>
              <div className="col col-md-4">{customer.email}</div>
              <div className="col col-md-4 text-end">
                <button
                  className="btn btn-outline-dark btn-sm"
                  onClick={() => setCustomer(customer)}
                >
                  +
                </button>
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  const renderMerchant = () => {
    if (role === "merchant") {
      if (merchant !== null) {
        return (
          <div className="card p-2 mb-3">
            <div className="row mx-0 align-items-center">
              <div className="col-12 col-md-10">{merchant.name}</div>
              <div className="col-12 col-md-2 text-end">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-danger"
                  onClick={() => setMerchant(null)}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div>
          <label>Elige un comercio</label>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Buscar..."
            value={merchantQuery}
            onChange={(e) => setMerchantQuery(e.target.value)}
          />
        </div>
      );
    }
  };

  const renderMerchants = () => {
    if (Array.isArray(merchants) && merchantQuery !== "" && merchant === null) {
      return merchants.map((merchant) => (
        <div
          key={merchant.merchant_id}
          className="row align-items-center py-2 small hover-light border-top"
        >
          <div className="col col-md-8">{merchant.name}</div>
          <div className="col col-md-4">
            <button
              type="button"
              className="btn btn-outline-dark w-100 btn-sm"
              onClick={(e) => {
                e.stopPropagation();
                setMerchant(merchant);
              }}
            >
              + Conectar
            </button>
          </div>
        </div>
      ));
    }
  };

  const renderButtons = () => {
    if (!disabled) {
      return (
        <div className="row mt-3 align-items-center">
          <div className="col-6">
            <button className="btn btn-primary" disabled={disabled}>
              Guardar
            </button>
          </div>
          <div className="col-6 text-right">
            <button
              type="button"
              className="btn btn-link text-secondary"
              onClick={clearModal}
            >
              Cancelar
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p>Buscar usuario por nombre o correo.</p>
      {customer === null && email === "" && (
        <input
          type="text"
          className="form-control mb-3"
          placeholder="Buscar..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      )}
      {renderCustomers()}
      <label>Rol</label>
      <select
        className="form-control mb-3"
        value={role}
        onChange={(e) => setRole(e.target.value)}
      >
        <option value="admin">Admin</option>
        <option value="manager">Manager</option>
        <option value="merchant">Merchant</option>
      </select>
      {renderMerchant()}
      {renderMerchants()}
      {renderButtons()}
    </form>
  );
};

export default UserForm;
