import React from "react";
import ChargeRow from "./ChargeRow";

const ChargesTable = ({ user, charges, showType }) => {
  const renderCharges = () => {
    if (Array.isArray(charges)) {
      if (charges.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay compras registradas.</td>
          </tr>
        );
      }
      return charges.map((charge) => (
        <ChargeRow
          key={charge.charge_id}
          showType={showType}
          charge={charge}
          user={user}
        />
      ));
    }
  };

  const renderCustomer = () => {
    if (!window.location.pathname.includes("customer/")) {
      return <td>Cliente</td>;
    }
  };

  const renderType = () => {
    if (showType) {
      return <td>Tipo</td>;
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>#ID</td>
            {renderType()}
            {renderCustomer()}
            <td>Comercio</td>
            <td>Fecha</td>
            <td>Monto</td>
            <td>Pago</td>
            <td>Estado</td>
            <td>Descuento</td>
          </tr>
        </thead>
        <tbody>{renderCharges()}</tbody>
      </table>
    </div>
  );
};

export default ChargesTable;
