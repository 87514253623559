import React from "react";
import VehicleRow from "./VehicleRow";
import HeaderRow from "../global/HeaderRow";

const VehiclesTable = ({ vehicles, hideUser }) => {
  const renderVehicles = () => {
    if (Array.isArray(vehicles)) {
      if (vehicles.length === 0) {
        return <p>No hay vehículos registrados.</p>;
      }
      return vehicles.map((vehicle) => (
        <VehicleRow
          key={vehicle.vehicle_id}
          vehicle={vehicle}
          hideUser={hideUser}
        />
      ));
    }
  };

  const headers = hideUser
    ? ["ID", "Marca", "Modelo", "Año"]
    : ["ID", "Marca", "Modelo", "Año", "Cliente"];

  return (
    <div>
      <HeaderRow headers={headers} />
      {renderVehicles()}
    </div>
  );
};

export default VehiclesTable;
