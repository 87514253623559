import React, { createContext, useReducer } from "react";
import VehiclesReducer from "../reducers/VehiclesReducer";
import VehiclesService from "../services/VehiclesService";
import { VEHICLES_RECEIVED } from "../types/vehicles";

const initialState = {
  vehicles: null,
  vehicle: null,
  spinner: false,
};

export const VehiclesContext = createContext(initialState);

export const VehiclesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(VehiclesReducer, initialState);
  const getVehicles = () => {
    VehiclesService.getVehicles().then((res) => {
      const { vehicles } = res.data;
      dispatch({ type: VEHICLES_RECEIVED, payload: vehicles });
    });
  };
  return (
    <VehiclesContext.Provider value={{ ...state, getVehicles }}>
      {children}
    </VehiclesContext.Provider>
  );
};
