import React, { useContext } from "react";
import LocationForm from "../locations/LocationForm";
import { MerchantsContext } from "../../context/MerchantsContext";
import { LocationsContext } from "../../context/LocationsContext";

const MerchantForm = ({ modifier, postMerchant }) => {
  const { merchant } = useContext(MerchantsContext);
  const { location } = useContext(LocationsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    merchant.address = location;
    postMerchant(merchant);
  };

  const renderForm = () => {
    if (merchant && merchant !== null) {
      const { name } = merchant;
      return (
        <form onSubmit={handleSubmit}>
          <label className="bold">Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => modifier("name", e.target.value)}
          />
          <label className="bold">Dirección</label>
          <LocationForm hideButtons={true} />
          <button type="submit" className="btn btn-primary w-100 my-3">
            Guardar
          </button>
          <button type="button" className="btn btn-link text-muted w-100">
            Cancelar
          </button>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default MerchantForm;
